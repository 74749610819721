<script setup>
defineProps({
    transaction: {
        type: Object
    }
});

function formatMoney(v) {
      let total = parseFloat(v);

      return total.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
}

function formatDate(d){
    
    let date = new Date(d);
    
    return date.toLocaleDateString('pt-BR', {timeZone: 'UTC'});

}

function formatStatus(status, type){
    
    if(status == 'Unpaid'){

        if(type == 'despesa'){
            return 'Não pago'
        }
        else if(type == 'receita'){
            return 'Não recebido'
        }
        else if(type == 'transferência'){
            return 'Não realizado'
        }

    }
    else{

        if(type == 'despesa'){
            return 'Pago'
        }
        else if(type == 'receita'){
            return 'Recebido'
        }
        else if(type == 'transferência'){
            return 'Realizado'
        }

    }

}

const emit = defineEmits(['edit', 'pay']);

const editTrasaction = (data) => {

    if(data.invoice_id > 0){
        location.href = '/fatura/'+data.invoice_id
    }
    else{
        emit('edit', data);
    }

}

const payTrasaction = (data) => {
    emit('pay', data);
}

const is_taled = (date, state) => {
    let data = new Date(date);

    if(data.setDate(data.getDate() + 1) < new Date() && state == 'Unpaid'){
        return 'transaction-taled';
    }
}
</script>

<template>
    <div class="col-12 hover-transaction" :class="is_taled(transaction.date, transaction.status)">

        <div class="row align-items-center row-transactions">

            <div class="col-2 col-lg-1 ms-3 text-center" v-tooltip="{
                            content: transaction.category_name,
                            distance: 15
                        }">
                <i :class="'fal ' +  transaction.category_icon +' row-transactions-icons' " :style="'color:'+transaction.category_color"></i>
            </div>
            <div v-if="transaction.invoice_id > 0" class="col-6 col-lg-8" @click="editTrasaction(transaction)" v-tooltip="{
                                                content:'Ver detalhes',
                                                distance: 15
                                            }">
                <div class="row align-items-center">
                    <div class="col-12 col-lg-6">
                        <p class="mb-1 text-sm d-lg-none">
                            {{ formatDate(transaction.date) }}
                        </p>
                        <p class="mb-1 text-sm d-none d-lg-block">
                            {{ formatDate(transaction.date) }} - {{ formatStatus(transaction.status, transaction.type) }}
                        </p>
                        <h6 class="mb-0 text-md transaction-description">{{ transaction.description }} {{ transaction.recurrence == 'Installments' ? '('+transaction.current_installment+'/'+transaction.end_installment+')' : '' }}</h6>
                        <p class="text-sm mt-n1" v-if="transaction.type == 'transferência'">
                            {{ transaction.account_name }} para {{ transaction.account_destiny_name }}
                        </p>
                    </div>
                    <div class="col-12 col-lg-3 text-lg-center d-none d-lg-block">
                        <img :src="transaction.account_icon" width="32" alt="" class="rounded-circle">
                    </div>
                    <div class="col-12 col-lg-3 text-lg-center">
                        <h6 class="mb-0 text-md" :class="transaction.type == 'despesa' ? 'text-danger' : transaction.type == 'receita' ? 'text-success' : ''">{{transaction.type == 'despesa' ? '-': ''}} {{ formatMoney(transaction.amount) }}</h6>
                    </div>
                </div>
            </div>
            <div v-else class="col-6 col-lg-8" @click="editTrasaction(transaction)" data-bs-toggle="modal" data-bs-target="#detailsTransaction" v-tooltip="{
                                                content:'Ver detalhes',
                                                distance: 15
                                            }">
                <div class="row align-items-center">
                    <div class="col-12 col-lg-6">
                        <p class="mb-1 text-sm d-lg-none">
                            {{ formatDate(transaction.date) }}
                        </p>
                        <p class="mb-1 text-sm d-none d-lg-block">
                            {{ formatDate(transaction.date) }} - {{ formatStatus(transaction.status, transaction.type) }}
                        </p>
                        <h6 class="mb-0 text-md transaction-description">{{ transaction.description }} {{ transaction.recurrence == 'Installments' ? '('+transaction.current_installment+'/'+transaction.end_installment+')' : '' }}</h6>
                        <p class="text-sm mt-n1" v-if="transaction.type == 'transferência'">
                            {{ transaction.account_name }} para {{ transaction.account_destiny_name }}
                        </p>
                    </div>
                    <div class="col-12 col-lg-3 text-lg-center d-none d-lg-block">
                        <img :src="transaction.account_icon" width="32" alt="" class="rounded-circle">
                    </div>
                    <div class="col-12 col-lg-3 text-lg-center">
                        <h6 
                            class="mb-0 text-md" 
                            :class="transaction.type == 'despesa' ? 'text-danger' : transaction.type == 'receita' ? 'text-success' : ''"
                        >
                            {{transaction.type == 'despesa' ? '-': ''}} {{ formatMoney(transaction.amount) }}
                        </h6>
                    </div>
                </div>
            </div>
            
            <div class="col-3 col-lg-2 text-end">
                <a @click="payTrasaction(transaction)" class="me-3 cursor-pointer">
                    <i 
                        class="fa-thumbs-up row-transactions-icons-actions"
                        :class="transaction.status == 'Unpaid' ? 'fal text-black-50' : 'fas text-success'"
                        v-tooltip="{
                            content: transaction.type == 'despesa' ? transaction.status == 'Unpaid' ? 'Pagar' : 'Pago' : transaction.status == 'Unpaid' ? 'Receber' : 'Recebido',
                            distance: 15
                        }"
                    >
                    </i>
                </a>
                <div class="col-12 col-lg-3 text-lg-center d-lg-none mt-3">
                    <p class="mb-0 text-md text-xs me-3">{{ transaction.account_name }}</p>
                </div>
            </div>

        </div>

    </div>
</template>

<style>
    .row-transactions{
        min-height: 85px;
    }

    .hover-transaction{
        border-bottom: solid 1px #dee2e6;
    }

    .hover-transaction:hover{
        background-color: #f6f9fc;
        cursor: pointer;
    }

    .row-transactions-icons{
        font-size: 25px;
    }

    .row-transactions-icons-actions{
        font-size: 20px;
    }

    .color-paid {
        color: #2dce89 !important;
    }

    .transaction-description{
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    .sticky-top{
        z-index: 900 !important;
    }

    .transaction-taled{
        background-color: #faffb8;
    }
</style>