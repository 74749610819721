<template>
    <Doughnut
      id="donut-id"
      :options="chartOptions"
      :data="chartData"
    />
  </template>
  
  <script>
  import { Doughnut } from 'vue-chartjs'
  import { Chart as ChartJS, ArcElement, Tooltip, Legend, Colors } from 'chart.js'
  import ChartDataLabels from 'chartjs-plugin-datalabels';
  import DoughnutLabel from 'chartjs-plugin-doughnutlabel-rebourne';
  import { getChartLabelPlugin } from 'chart.js-plugin-labels-dv';
  
  ChartJS.register(ArcElement, Tooltip, Legend, Colors, ChartDataLabels, DoughnutLabel, getChartLabelPlugin())
  
  export default {
    name: 'DonutChart',
    components: { Doughnut },
    props: {
      chartData: {
          type: Object,
          required: true
        },
      chartOptions: {
        type: Object,
        default: () => {}
      }
    }
  }
  </script>