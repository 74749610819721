<script setup>
defineProps({
    bank: {
        type: String,
        required: true
    },
    balance: {
        type: Number,
        required: true,
    },
    id: {
        type: String,
        default: "",
    },
    icon: {
        type: String,
        default: "",
    },
    status: {
        type: String,
        default: "text-success"
    }
});

function formatMoney(v) {
      let total = parseFloat(v);

      return total.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
    }
</script>

<template>
    <div class="col-12">

        <div class="row align-items-center row-accounts">

            <div class="col-2 col-lg-1 ms-2 ms-lg-3">
                <img :src="icon" alt="Banco Logo" width="50" class="rounded-circle">
            </div>
            <div class="col-4">
                <h6 class="ms-2 mt-n1 mb-n1">{{ bank }}</h6>
            </div>
            <div class="col-5 col-lg-6 text-end">
                <span :class="status">
                    <b>
                        {{ formatMoney(balance) }}
                    </b>
                </span>
            </div>

        </div>

    </div>
</template>

<style>
    .row-accounts{
        min-height: 70px;
    }
</style>