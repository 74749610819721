<script setup>
import { onBeforeUnmount, onBeforeMount } from "vue";
import { useStore } from "vuex";
import cookie from '../assets/js/cookies.js';

import axios from "axios";
const body = document.getElementsByTagName("body")[0];

const store = useStore();

const submit = () => {

  let data = new FormData();

  store.state.name.hasError = false;
  store.state.email.hasError = false;
  store.state.password.hasError = false;
  store.state.terms.hasError = false;

  data.append('name', store.state.name.value);
  data.append('email', store.state.email.value);
  data.append('password', store.state.password.value);
  data.append('accept_terms', store.state.terms.checked);

  axios.post('/users', data)
  .then(res => {

    if(res.data.error){

      let messages = res.data.messages;
      
      Object.entries(messages).forEach((value) => {
        
        if(value[0] == 'name'){
          store.state.name.hasError = true;
          store.state.name.messageError = value[1];
        }

        else if(value[0] == 'email'){
          store.state.email.hasError = true;
          store.state.email.messageError = value[1];
        }

        else if(value[0] == 'password'){
          store.state.password.hasError = true;
          store.state.password.messageError = value[1];
        }

        else if(value[0] == 'accept_terms'){
          store.state.terms.hasError = true;
          store.state.terms.messageError = value[1];
        }

      })

    }
    else{

      let email = store.state.email.value;
      let password = store.state.password.value;

      login(email, password);
      
    }
    
  })
  .catch(err => {
    console.error(err.message); 
  })

}

const login = (email, password) => {

  let data = new FormData();

  data.append('email', email);
  data.append('password', password);
  data.append('remember', false);
  
  axios.post('/auth',data)
  .then(res => {

    if(res.data.error){

    let messages = res.data.messages;

      Object.entries(messages).forEach((value) => {
      
        if(value[0] == 'name'){
          store.state.name.hasError = true;
          store.state.name.messageError = value[1];
        }

        else if(value[0] == 'email'){
          store.state.email.hasError = true;
          store.state.email.messageError = value[1];
        }

        else if(value[0] == 'password'){
          store.state.password.hasError = true;
          store.state.password.messageError = value[1];
        }

        else if(value[0] == 'accept_terms'){
          store.state.terms.hasError = true;
          store.state.terms.messageError = value[1];
        }

      })

    }
    else{

      cookie.add('user_agive_session', res.data, 86400);

      window.location.href = '/inicio';

    }

  })
  .catch(err => {
    console.error(err); 
  })

}

onBeforeMount(() => {
  store.state.hideConfigButton = true;
  store.state.showNavbar = false;
  store.state.showSidenav = false;
  store.state.showFooter = false;
  body.classList.remove("bg-gray-100");

  store.state.name = {
    hasError: false,
    messageError: '',
    value: '',
  };

  store.state.email = {
    hasError: false,
    messageError: '',
    value: '',
  };

  store.state.password = {
    hasError: false,
    messageError: '',
    value: '',
  };

  store.state.terms = {
    hasError: false,
    messageError: '',
    checked: false,
  };

  store.state.user = {
    isLogged: false,
    id: 0,
    accept_terms: "",
    account_type: '',
    birthdate: '',
    email: '',
    sex: '',
    status: ''
  }

});
onBeforeUnmount(() => {
  store.state.hideConfigButton = false;
  store.state.showNavbar = true;
  store.state.showSidenav = true;
  store.state.showFooter = true;
  body.classList.add("bg-gray-100");
});
</script>
<template>
  <main class="main-content mt-0">
    <div
      class="page-header align-items-start min-vh-50 pt-5 pb-11 m-3 border-radius-lg bg-gradient-success"
    >
      <span class="mask bg-gradient-dark opacity-6"></span>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-5 text-center mx-auto">
            <h1 class="text-white mb-2 mt-3">Crie sua conta </h1>
            <p class="text-lead text-white">
              Começe a controlar sua grana
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
        <div class="col-xl-4 col-lg-5 col-md-7 mx-auto">
          <div class="card z-index-0">
            
            <div class="card-body">
              <div class="mb-3">
                <input 
                  type="text" 
                  class="form-control form-control-lg" 
                  id="name" 
                  v-model="store.state.name.value" 
                  placeholder="Nome Completo"
                  :class="store.state.name.hasError ? 'is-invalid' : ''"
                >
                <div id="nameFeedback" class="invalid-feedback">
                  {{ store.state.name.messageError }}
                </div>
              </div>
                <div class="mb-3">
                  <input 
                    type="email" 
                    class="form-control form-control-lg" 
                    id="email" 
                    v-model="store.state.email.value" 
                    placeholder="E-mail"
                    :class="store.state.email.hasError ? 'is-invalid' : ''"
                  >
                  <div id="emailFeedback" class="invalid-feedback">
                    {{ store.state.email.messageError }}
                  </div>
                </div>
                <div class="mb-3">
                  <input 
                    type="password" 
                    class="form-control form-control-lg" 
                    id="password" 
                    v-model="store.state.password.value" 
                    placeholder="Senha"
                    :class="store.state.password.hasError ? 'is-invalid' : ''"
                  >
                  <div id="passwordFeedback" class="invalid-feedback">
                    {{ store.state.password.messageError }}
                  </div>
                </div>
                <div class="mb-3">
                  <div class="form-check">
                      <input 
                        class="form-check-input" 
                        type="checkbox" 
                        id="termsCheck" 
                        v-model="store.state.terms.checked"
                        :class="store.state.terms.hasError ? 'is-invalid' : ''"
                      >
                      <label class="form-check-label" for="termsCheck">
                        Li e concordo com os
                        <a href="javascript:;" class="text-dark font-weight-bolder"
                          >Termos de uso</a
                        >
                      </label>
                      <div id="termsCheckFeedback" class="invalid-feedback">
                        {{ store.state.terms.messageError }}
                      </div>
                      
                  </div>
                  
                </div>
                
                <div class="text-center">
                  <button
                    class="my-4 mb-2 btn-lg btn bg-gradient-success w-100"
                    @click="submit()"
                  >
                    Começar a usar
                  </button>
                  
                </div>
                <p class="text-sm mt-3 mb-0">
                  Já sou cadastrado.
                  <a href="javascript:;" class="text-dark font-weight-bolder"
                    >Quero fazer login!</a
                  >
                </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
